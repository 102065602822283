<template>
  <v-app>
    <section class="analiz2">
      <v-container>
        <v-card class="border-radius-xl card-shadow pa-10">
          <v-row justify="space-between">
            <v-col>
              <h1>
                {{ asuulga.name }}xxx
              </h1>
              <p v-if="results" class="mb-1">
                Оролцогчдын тооxxx: {{ results.length }}
              </p>
              <p>Нийт оноо: {{ asuulga.finalScore }}</p>
            </v-col>
            <v-col class="text-right">
              <v-btn
                title="Мэдээлэл татаx"
                @click="_download"
                dark
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase btn-success bg-gradient-success"
                ><v-icon size="22" class="mr-2">mdi-file-excel</v-icon>татаx</v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            v-if="schools && loading == false"
            :headers="headers"
            :items="schools"
            hide-default-footer
            :items-per-page="-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td style="width: 1%">{{ props.index + 1 }}</td>
                <td class="text-start">
                  {{ props.item.name }} {{ props.item.ppschool }}
                </td>
                <td>{{ props.item.totalScore }}</td>
                <td>{{ props.item.numberOfStudents }}</td>
                <td>{{ props.item.interestedNumberOfStudents }}</td>
                <td>{{ Math.round(props.item.interestedProcent) }}%</td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="d-flex fle-row justify-center">
            <v-progress-circular
              :size="70"
              :width="7"
              class="mt-10"
              color="red"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    downloadLoading: false,
    schools: null,
    loading: true,
    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Сургуулийн нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Дундаж",
        align: "start",
        sortable: true,
        value: "totalScore",
      },
      {
        text: "Сурагчийн тоо",
        align: "start",
        sortable: true,
        value: "numberOfStudents",
      },
      {
        text: "Бүртгүүлсэн тоо",
        align: "start",
        sortable: true,
        value: "interestedNumberOfStudents",
      },
      {
        text: "Ирцийн xувь",
        align: "start",
        sortable: true,
        value: "interestedProcent",
      },
    ],
  }),
  props: {
    asuulga: {
      type: Object,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    fb.db
      .collection("schools")
      .where("realschool", "==", true)
      .where("schoolIndex", "==", this.userData.schoolIndex)
      .get()
      .then((docss) => {
        this.schools = [];
        var counter = 1;
        docss.forEach((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          school.index = counter;
          var xx = this._getAvgScore2(school);
          school.totalScore = xx[0];
          school.numberOfStudents = xx[1];
          fb.db
            .doc(this.asuulga.categoryRef.path)
            .collection("lesson-students")
            .where("ppschool", "==", school.ref.path)
            .get()
            .then((docs) => {
              school.interestedNumberOfStudents = docs.size;
              school.interestedProcent =
                100 *
                (school.interestedNumberOfStudents
                  ? school.numberOfStudents / school.interestedNumberOfStudents
                  : 0);

              this._saveReportData(school, xx);

              this.schools.push(school);
              counter++;

              if (docss.size + 1 == counter) {
                this.loading = false;
              }
            });
        });
      });
  },
  methods: {
    _download() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["Id", "Title", "Author", "Readings", "Date"];
        excel.export_json_to_excel({
          header: tHeader,
          data: [],
          filename: "tmp",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });

      // import("@/vendor/Export2Excel").then((excel) => {
      //   var tHeader = [];
      //   for (var head of this.headers) {
      //     tHeader.push(head.text);
      //   }
      //   var data = [];
      //   for (const school of this.schools) {
      //     var row = [];
      //     row.push(school.index);
      //     row.push(school.name);
      //     row.push(school.totalScore);
      //     row.push(school.numberOfStudents);
      //     data.push(row);
      //   }

      //   excel.export_json_to_excel({
      //     header: tHeader,
      //     data,
      //     filename: "school_analyze_" + this.asuulga.name,
      //     autoWidth: true,
      //     bookType: "xlsx",
      //   });
      //   this.downloadLoading = false;
      // });
    },

    _saveReportData(school, xx) {
      var dataReport = { asuulgaRef: this.asuulga.ref, asuulga: this.asuulga };
      if (xx[0]) dataReport.averageScore = parseFloat(xx[0]);
      else dataReport.averageScore = 0;
      if (xx[1]) dataReport.numberOfStudents = xx[1];
      else dataReport.numberOfStudents = 0;

      school.ref
        .collection("soril-reports")
        .doc(this.asuulga.id)
        .set(dataReport, { merge: true })
        .then(() => {});

      dataReport.schoolName = school.name;
      dataReport.schoolRef = school.ref;

      this.asuulga.ref
        .collection("schools-reports")
        .doc(school.id)
        .set(dataReport, { merge: true })
        .then(() => {
          console.log("saveddd!!!");
        });
    },
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results) {
        for (var i = 0; i < this.results.length; i++) {
          var student = this.results[i];
          if (student.schoolName && school.name == student.schoolName.trim()) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.analiz2 table,
.analiz2 th,
.analiz2 td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
.analiz2 td {
  width: 30px;
  padding: 10px;
}
</style>
