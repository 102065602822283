<template>
  <v-app>
    <v-card class="border-radius-xl card-shadow mt-10 mx-4">
      <v-col md="10" lg="12" class="mx-auto">
        <v-data-table
          v-if="students && students.length > 0"
          :headers="headers"
          :items="students"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="">{{ props.item.index }}</td>
              <td class="">{{ props.item.answererName }}</td>
              <td
                v-text="
                  props.item.timeLeft > 0
                    ? props.item.timeLeft + ' Минут'
                    : 'Дууссан'
                "
              ></td>
              <td>
                <v-btn
                  small
                  class="bg-gradient-danger white--text"
                  @click="resetTest(props.item)"
                  >Ахиж Өгүүлэх</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <h5 v-else class="text-center red--text mb-10">
          Одоогоор шалгалт эxлүүлсэн сурагч байxгүй байна!
        </h5>
      </v-col>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);

const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    school: null,
    onlyOurSchool: true,
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulga: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: false,
    rules: [],
    students: null,

    headers: [
      {
        text: "No",
        sortable: true,
        value: "index",
        width: "4%",
      },
      {
        text: "Нэр",
        sortable: false,
        value: "answererName",
      },
      {
        text: "Үлдсэн цаг",
        sortable: true,
        value: "timeLeft",
      },
      {
        text: "",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
    fromEYESH: {
      type: Boolean,
      required: true,
    },
    // maxNumber: {
    //   required: false,
    // },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    totalScore() {
      //return this.categories.reduce((pre, el) => pre + el.totalScore?el.totalScore:0, 0);
      var score = 0;
      if (this.categories) {
        for (var category of this.categories) {
          if (category.totalScore) {
            score = score + category.totalScore;
          }
        }
      }

      return Math.round(score);
    },
    results2() {
      if (this.results)
        return this.results.filter((student) => student.skipped != true);
      else return [];
    },
    filteredAnswerers() {
      if (this.results && this.userData.ppschool) {
        //  return this.results.filter(
        //   (student) =>
        //     student.answerer.ref.parent.parent.path == this.userData.ppschool
        // );
        if (this.onlyOurSchool) {
          var tmp = [];
          for (var result of this.results) {
            if (
              result.answerer.role == "superadmin" ||
              result.answerer.ref.parent.parent.path == this.userData.ppschool
            )
              tmp.push(result);
          }
          return tmp;
        } else return this.results;
      } else {
        if (
          this.userData.role == "aspecialist" ||
          this.userData.role == "superadmin"
        ) {
          return this.results;
        } else return [];
      }
    },
  },
  created() {
    if (this.userData.school) {
      this.userData.school.ref.get().then((doc) => {
        this.school = doc.data();
        this.school.id = doc.id;
        this.school.ref = doc.ref;
        console.log(this.school);
      });
    }
    fb.dbeyesh800
      .collection("schools")
      .get()
      .then((docs) => {
        this.schools = [];
        var counter = 1;
        docs.forEach((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          school.index = counter;
          // var xx = this._getAvgScore2(school);
          // school.totalScore = xx[0];
          // school.numberOfStudents = xx[1];
          //this._saveReportData(school, xx);
          this.schools.push(school);
          counter++;
        });
      });

    fb.dbeyesh800.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
    });
    console.log(this.sorilId);
    if (this.userData.school) {
      fb.dbeyesh800
        .doc(this.sorilId)
        .collection("starting-times")
        .where("schoolId", "==", this.userData.school.id)
        .onSnapshot((docs) => {
          this.students = [];
          var counter = 1;
          docs.forEach(async (doc) => {
            let time = doc.data();
            time.ref = doc.ref;
            time.id = doc.id;
            time.index = counter;
            time.timeLeft =
              this.asuulga.duration -
              new Date(new Date() - new Date(time.startingTime)).getMinutes();

            if (time.lastName) {
              time.answererName = time.firstName + ". " + time.lastName[0];
            } else {
              time.answererName = time.firstName;
            }
            console.log(time);

            this.students.push(time);
            counter++;
          });
        });
    } else {
      fb.dbeyesh800
        .doc(this.sorilId)
        .collection("starting-times")
        .onSnapshot((docs) => {
          this.students = [];
          var counter = 0;
          docs.forEach(async (doc) => {
            let time = doc.data();
            time.ref = doc.ref;
            time.id = doc.id;
            time.index = ++counter;
            time.timeLeft =
              this.asuulga.duration -
              new Date(new Date() - new Date(time.startingTime)).getMinutes();

            await time.studentRef.get().then((doc) => {
              if (doc.data().lastName) {
                time.answererName =
                  doc.data().firstName + ". " + doc.data().lastName[0];
              } else {
                time.answererName = doc.data().firstName;
              }
            });
            console.log(time);

            this.students.push(time);
          });
        });
    }
  },
  methods: {
    resetTest(student) {
      var yes = confirm("Устгах уу?");
      if (yes) {
        this.asuulga.ref
          .collection("results")
          .doc(student.id)
          .get((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("results-simplified")
          .doc(student.id)
          .get((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("daalgavar-analyzed")
          .doc(student.id)
          .get((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("starting-times")
          .doc(student.id)
          .update({ reset: true });
      }
    },
    _closeSoril() {
      console.log(this.asuulga.ref.path);
      this.asuulga.ref
        .collection("school-closed-results")
        .doc(this.userData.school.id)
        .set({
          averageScore: this._getAverageTotalScore()
            ? parseFloat(this._getAverageTotalScore())
            : null,
          maxTotalScore: this.maxTotalScore ? this.maxTotalScore : null,
          totalScore: this.totalScore ? this.totalScore : null,
          numberOfParticipants: this.filteredAnswerers.length
            ? this.filteredAnswerers.length
            : 0,
          createdAt: new Date(),
          createdByRef: this.userData.ref,
          createdByName: this.userData.firstName,
          school: this.school ? this.school : null,
        })
        .then(() => {});
    },
    _getCapitalizedName(name) {
      return name && name.length > 0
        ? name.charAt(0).toUpperCase() + name.slice(1)
        : null;
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            this._getCapitalizedName(
              item.lastName.substring(0, item.prefixName)
            ) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name =
            this._getCapitalizedName(item.lastName[0]) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      }
      return name;
    },

    _deleteStudentResult(result) {
      console.log(result.id, result.ref.path);
      var yes = confirm("TODO: Устгаx уу?");
      if (yes) {
        result.ref.parent.parent
          .collection("results")
          .doc(result.id)
          .delete()
          .then(() => {
            result.ref.delete().then(() => {
              console.log("DONE!");
            });
          });
      }
    },
    _publicLink() {
      this.$router.push({
        name: "StudentShowAssignmentAllResults2",
        params: { sorilId: this.asuulga.ref.path },
      });

      //  this.$router.push({
      //   name: "PublicStudentsScore",
      //   params: {
      //     sorilId: this.asuulga.id,
      //   },
      // });
    },
    _saveAllDeepAnalysis() {
      console.log(this.results.length);
      var counter = 0;
      for (var result of this.results) {
        if (result.deepAnalyzed == false) {
          if (!result.skipped) {
            this._deepanalyze(result);
            counter++;
            console.log(counter);
          }
        }
      }
      console.log(counter);
    },
    async _deepanalyze(item) {
      console.log(item.ref.path);
      console.log(item);
      var batch = fb.dbeyesh800.batch();
      var result = await this.asuulga.ref
        .collection("results")
        .doc(item.ref.id)
        .get();

      var categories = result.data().categories;
      // for(var category of categories){
      var category = categories[0];
      if (category && category.catScored) {
        for (var question of category.questions) {
          for (var answer of question.answers) {
            if (answer.answered) {
              //yyy.push({index:counter,name:answer.name, answered:true})
              var x = {
                questionIndex: question.index,
                answerName: answer.name,
                answered: true,
              };
              var yy = {};
              yy[x.answerName] = fb.firestore.FieldValue.increment(1);
              yy.questionIndex = question.index;
              if (answer.correct) yy.correctAnswer = answer.name;

              var refRef = this.asuulga.ref
                .collection("daalgavar-analyzed")
                .doc(question.index.toString());

              batch.set(refRef, yy, { merge: true });
            } else {
              //yyy.push({index:counter,name:answer.name, answered:false})
            }
          }
        }
        batch.commit().then(() => {
          console.log("donexxx");
          item.ref.update({ deepAnalyzed: true }).then(() => {
            console.log("ok", item.ref.path);
          });
          if (
            this.asuulga.deepAnalyzed == undefined ||
            this.asuulga.deepAnalyzed == false
          )
            this.asuulga.ref.update({ deepAnalyzed: true });
        });
      }
    },
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results2) {
        for (var i = 0; i < this.results2.length; i++) {
          var student = this.results2[i];
          if (school.name == student.schoolName) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },
    _closeAsuulga2() {
      console.log(this.sorilId);
      console.log(this.asuulga.ref.path);

      for (var student of this.results2) {
        console.log(
          student.index,
          student.totalScore,
          student.ref.path,
          student.id
        );
      }
    },
    _closeAsuulga() {
      if (this.asuulga.finished) {
        this.asuulga.ref.update({ finished: false });
      } else {
        this.$swal({
          title: "Сорилд оролцоxод бэлэн үү?",
          text: "Тийм гэсэн товч дарсанаар сорил эxлэнэ!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var finalData = {
              finished: !this.asuulga.finished,
            };
            if (this.results2)
              finalData.finalNumberOfParticipants = this.results2.length;
            else finalData.finalNumberOfParticipants = -1;
            if (this.maxTotalScore)
              finalData.maxTotalScore = this.maxTotalScore;
            else finalData.finalMaxTotalScore = -1;
            if (
              this.sumOfAverageTotalScores &&
              finalData.finalNumberOfParticipants
            ) {
              finalData.finalAverageScore = parseFloat(
                (
                  this.sumOfAverageTotalScores /
                  finalData.finalNumberOfParticipants
                ).toFixed(2)
              );
            } else {
              finalData.finalAverageScore = -1;
            }
            this.asuulga.ref.update(finalData).then(() => {
              if (this.schools && this.schools.length > 0) {
                var batch = fb.dbeyesh800.batch();
                for (var school of this.schools) {
                  var xx = this._getAvgScore2(school);
                  var dataReport = {
                    asuulgaRef: this.asuulga.ref,
                    asuulga: this.asuulga,
                  };
                  if (xx[0]) dataReport.averageScore = parseFloat(xx[0]);
                  else dataReport.averageScore = 0;
                  if (xx[1]) dataReport.numberOfStudents = xx[1];
                  else dataReport.numberOfStudents = 0;

                  batch.set(
                    school.ref.collection("soril-reports").doc(this.asuulga.id),
                    dataReport,
                    { merge: true }
                  );

                  dataReport.schoolName = school.name;
                  dataReport.schoolRef = school.ref;

                  batch.set(
                    this.asuulga.ref
                      .collection("schools-reports")
                      .doc(school.id),
                    dataReport,
                    { merge: true }
                  );
                }
                for (var student of this.results2) {
                  batch.update(
                    this.asuulga.ref.collection("results").doc(student.id),
                    { ranked: student.index }
                  );
                }
                batch.commit().then(() => {
                  this.$swal.fire(
                    "Танд амжит xүсье",
                    "Шалгалт амжилттай xаагдлаа!",
                    "success"
                  );
                });
              }
            });
          } else {
            console.log("NO");
          }
        });
      }
    },
    _download() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        var tHeader = [];
        for (var head of this.headers) {
          tHeader.push(head.text);
        }
        var data = [];
        for (const result of this.results) {
          if (!result.skipped) {
            var row = [];
            row.push(result.index);
            row.push(result.answerer.firstName);
            row.push(result.schoolName);
            row.push(result.totalScore);
            row.push(result.percent);

            for (var cat of result.categories) {
              row.push(cat.catScored);
            }
            data.push(row);
          }
        }

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "all_results_" + this.asuulga.name,
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    _delete(student) {
      var x = "";
      if (student.skipped)
        x =
          student.answerer.firstName + ": тооцоололд оруулаxыг xүсэж байна уу?";
      else
        x =
          student.answerer.firstName + ": тооцооллоос xасаxыг xүсэж байна уу?";

      var yes = confirm(x);
      if (yes) {
        if (!student.skipped) student.ref.update({ skipped: true });
        else student.ref.update({ skipped: !student.skipped });
      }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },

    _sendAsuulga(student) {
      fb.dbeyesh800
        .doc(student.ref.path.replace("results-simplified", "results"))
        .get()
        .then((doc) => {
          var cat = doc.data().categories;

          var scored = 0;
          console.log(cat);
          for (
            var catIndex = 0;
            catIndex < this.categories.length;
            catIndex++
          ) {
            if (
              this.categories[catIndex].questions &&
              this.categories[catIndex].questions.length > 0
            ) {
              cat[catIndex].catScored = 0;
              console.log(this.categories);

              if (
                this.categories[catIndex].categoryScoreRequired &&
                this.categories[catIndex].givingScorePerQuestion == false
              ) {
                var x1 = this._listToString(this.categories[catIndex].keyss);
                var x2 = this._listToString(
                  this.categories[catIndex].studentKeyss
                );

                if (x1 == x2) {
                  cat[catIndex].catScored = cat[catIndex].totalScore;
                }
              } else {
                if (this.categories[catIndex].questions) {
                  for (
                    var questionIndex = 0;
                    questionIndex < this.categories[catIndex].questions.length;
                    questionIndex++
                  ) {
                    cat[catIndex].questions[questionIndex].questionScored = 0;
                    if (
                      cat[catIndex].questions[questionIndex] &&
                      cat[catIndex].questions[questionIndex].answers
                    ) {
                      for (
                        var answerIndex = 0;
                        answerIndex <
                        cat[catIndex].questions[questionIndex].answers.length;
                        answerIndex++
                      ) {
                        if (
                          cat[catIndex].questions[questionIndex].answers[
                            answerIndex
                          ].answered &&
                          this.categories[catIndex].questions[questionIndex]
                            .answers[answerIndex].correct
                        ) {
                          cat[catIndex].questions[
                            questionIndex
                          ].questionScored =
                            cat[catIndex].questions[questionIndex]
                              .questionScored +
                            this.categories[catIndex].questions[questionIndex]
                              .score;
                        }
                      }
                    }
                    cat[catIndex].catScored =
                      cat[catIndex].catScored +
                      cat[catIndex].questions[questionIndex].questionScored;
                  }
                }
              }

              scored = scored + cat[catIndex].catScored;
              console.log(scored);

              var sendData = {
                shalgaltId: this.asuulga.id,
                shalgaltRef: this.asuulga.ref,
                answererRef: null,
                answererId: student.answerer.email,
                categories: cat,
                sendAt: fb.firestore.FieldValue.serverTimestamp(),
                answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                totalScore: scored,
                answerer: student.answerer,
                deepdataanalyzed: false,
              };
              var sendDataSimplified = {
                shalgaltId: this.asuulga.id,
                shalgaltRef: this.asuulga.ref,
                answererRef: null,
                answererId: student.answerer.email,
                categories: this._getSimplified(this.categories, cat),
                sendAt: fb.firestore.FieldValue.serverTimestamp(),
                answeredAt: fb.firestore.FieldValue.serverTimestamp(),
                totalScore: scored,
                answerer: student.answerer,
                deepdataanalyzed: false,
              };

              if (student.schoolName && student.schoolRef) {
                sendData.schoolName = student.schoolName;
                sendData.schoolRef = student.schoolRef;
                sendDataSimplified.schoolName = student.schoolName;
                sendDataSimplified.schoolRef = student.schoolRef;
              } else {
                sendData.schoolName = null;
                sendData.schoolRef = null;
                sendDataSimplified.schoolName = null;
                sendDataSimplified.schoolRef = null;
              }

              console.log(sendDataSimplified);

              this.asuulga.ref
                .collection("results-simplified")
                .doc(student.answerer.id)
                .set(sendDataSimplified, { merge: true })
                .then(() => {
                  this.asuulga.ref
                    .collection("results")
                    .doc(student.answerer.id)
                    .set(sendData, { merge: true })
                    .then(() => {
                      console.log("success", student.answerer.ref.path);
                      if (this.isTesting == false) this._deepanalyze(sendData);
                      this.sent = true;
                    })
                    .catch(function () {});

                  this.asuulga.ref
                    .collection("starting-times")
                    .doc(student.answerer.id)
                    .delete();
                });
            }
          }
        });
    },
    _getSimplified(categories, studCategories) {
      var xxCategories = [];
      for (var catIndex; catIndex < categories.length; catIndex++) {
        if (studCategories[catIndex] && categories[catIndex]) {
          var categoryTMP = {
            catScored: studCategories[catIndex].catScored
              ? studCategories[catIndex].catScored
              : 0,
            totalScore: categories[catIndex].totalScore
              ? categories[catIndex].totalScore
              : 0,
          };
        }

        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },

    _reportSchool() {
      this.$router.push({
        name: "ReportSchool",
        params: {
          results: this.results,
          asuulga: this.asuulga,
        },
      });
    },
    async _analyzeScores() {
      if (this.categories[0]) {
        var x = await this.categories[0].ref.collection("questions").get();
        if (!x.empty && x.size > 0) {
          this.$router.push({
            name: "AllQuestionsAnalysis",
            params: {
              asuulga: this.asuulga,
              numberOfQuestions: x.size,
            },
          });
        }
      }
    },
    _detail(item) {
      //assignmentsdatabase/jw9gIycm1fuyguz3JeXA/results/h0jYmrkH0WY1ipQrKGhF
      // await assign.ref
      //.collection("results")
      //.doc(this.userData.id)

      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: this.asuulga.ref.path + "/results/" + item.id,
          showSlide: false,
        },
      });
    },
    async _setSchool() {
      for (var result of this.results) {
        if (result.schoolName == undefined) {
          console.log(result.ref.path);

          if (result.answerer["department-2021"]) {
            var ppschool =
              result.answerer["department-2021"].parent.parent.path;
            var sch = await fb.dbeyesh800.doc(ppschool).get();
            if (sch.exists) {
              var dataa = {};
              if (sch.ref) dataa.schoolRef = sch.ref;
              if (sch.data().name) dataa.schoolName = sch.data().name;
              result.ref.update(dataa).then(() => {
                console.log(result.ref.path);
              });
            }
          } else {
            if (result.answerer.ppschool) {
              var ppschool2 = result.answerer.ppschool;
              var sch2 = await fb.dbeyesh800.doc(ppschool2).get();
              if (sch2.exists) {
                var dataa2 = {};
                if (sch2.ref) dataa2.schoolRef = sch2.ref;
                if (sch2.data().name) dataa2.schoolName = sch2.data().name;
                result.ref.update(dataa2).then(() => {
                  console.log(result.ref.path);
                });
              }
            }
          }
        }
      }
    },

    // async findSchools() {
    //   this.schools = [];
    //   var x = await fb.db.collection("schools").get();
    //   if (!x.empty) {
    //     x.docs.forEach((doc) => {
    //       let school = doc.data();
    //       school.id = doc.id;
    //       school.ref = doc.ref;
    //       this.schools.push(school);
    //     });
    //   }
    // },
    _getSchoolName(schoolPath) {
      var x = null;
      if (this.schools) {
        for (var school of this.schools) {
          if (school.ref.path == schoolPath) {
            x = school.name;
            break;
          }
        }
      }

      return x;
    },
    _getAverageTotalScore() {
      if (this.results2)
        return (this.sumOfAverageTotalScores / this.results2.length).toFixed(2);
      else return 0;
    },
    _redirectResultsDetail(sorilId, tabIndex) {
      this.$router.push({
        name: "StudentShowAssignmentAllResultsDetail",
        params: {
          sorilId: sorilId,
          tabIndex: tabIndex,
        },
      });
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
 