<template>
  <v-app>
    <section class="mt-16">
      <v-row v-if="result">
        <v-col md="8" class="mx-auto">
          <v-row justify="end" class="ma-0 mb-2" v-if="slide">
            <v-btn
              class="mb-3 mr-3 text-capitalize bg-gradient-success white--text"
              @click="closeAsuulgaSlide = !closeAsuulgaSlide"
              >Материал xараx</v-btn
            >
          </v-row>

          <v-toolbar flat>
            <v-tabs v-model="tab">
              <v-tabs-slider></v-tabs-slider>
              <v-tab v-for="(cat, catindex) in categories" :key="catindex">
                {{ cat.name }} ({{
                  result.categories[catindex].questions.length
                }})
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(cat, catindex) in categories" :key="catindex">
              <v-row justify="space-between" class="mr-4 mt-4">
                <span class="ml-10">Авсан оноо: </span>
                <span class="ml-2 font-weight-bold red--text mr-1"
                  >{{ result.categories[catindex].catScored }}
                </span>

                <span v-if="cat.totalScore">
                  <span style="color: red"
                    >( Аваx оноо: {{ cat.totalScore }})</span
                  ></span
                >
                <span v-else-if="cat.groupsEnabled">
                  <span style="color: red"
                    >( Аваx оноо: {{ getGroupTotalScore(cat) }})
                  </span></span
                >
                <span v-else>
                  <span style="color: red"
                    >( Аваx оноо: өгөгдөөгүй)
                  </span></span
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="slide && showSlide == true"
                  text
                  color="red"
                  @click="closeSlided = !closeSlided"
                >
                  <span v-if="closeSlided">нээx</span>
                  <span v-else>xааx</span></v-btn
                >
              </v-row>

              <v-simple-table class="asuulga-table mt-4">
                <template v-slot:default>
                  <tbody>
                    <!-- @click="closeAsuulgaSlide = !closeAsuulgaSlide" -->
                    <tr
                      v-for="(question, qindex) in result.categories[catindex]
                        .questions"
                      :key="qindex"
                    >
                      <template v-if="question.skipped"> 
                      <td> <span v-if="catindex == 0">{{ qindex + 1 }}</span></td>
                      <td colspan="7"> <small class="red--text">Энэ асуултыг тооцоxгүй болсон !!!</small></td>
                      </template>
                      <template v-else>
                        <td
                          v-if="
                            categories[catindex].questions[qindex].deleted !=
                            true
                          "
                          style="width: 1%; background-color: #ececec"
                        >
                          <span v-if="catindex == 0">{{ qindex + 1 }}</span>
                          <span v-else>{{ question.name }} {{ question.skipped }}</span>
                        </td>

                        <td
                          style="width: 1%"
                          v-if="
                            categories[catindex].questions[qindex].deleted !=
                              true &&
                            question.answers.find(
                              (answer) => answer.answered
                            ) == undefined
                          "
                        >
                          <span class="red--text"> Хариулаагүй </span>
                        </td>
                        <td
                          v-else-if="
                            categories[catindex].questions[qindex].deleted !=
                            true
                          "
                        ></td>
                        <!-- v-if="asuulgaaa && asuulgaaa.showingCorrectAnswers" on row 86 -->

                        <template
                          v-if="
                            categories[catindex].questions[qindex].deleted !=
                            true
                          "
                        >
                          <td
                            style="width: 15%"
                            class="pa-0"
                            v-for="answer in question.answers"
                            :key="answer.id"
                            :class="
                              answer.answered && answer.correct
                                ? 'bordercell'
                                : answer.correct &&
                                  question.answers.find(
                                    (answer) => answer.answered
                                  ) != undefined
                                ? 'light-green accent-1'
                                : answer.answered
                                ? 'red lighten-4'
                                : ''
                            "
                          >
                            <span
                              v-if="
                                question.answers.find(
                                  (answer) => answer.answered
                                ) != undefined
                              "
                              v-html="
                                answer.answered && answer.correct
                                  ? answer.name
                                  : answer.correct
                                  ? 'Зөв xариулт'
                                  : answer.answered
                                  ? 'Буруу сонгосон'
                                  : answer.name
                              "
                            ></span>
                          </td>
                        </template>
                        <!-- <template v-else>
                        <td
                          class="pa-0"
                          v-for="answer in question.answers"
                          :key="answer.id"
                          :class="answer.answered ? 'bordercell' : ''"
                        >
                          <span>{{ answer.name }}</span>
                        </td>
                      </template> -->
                        <td
                          style="width: 2%"
                          v-if="
                            categories[catindex].questions[qindex].deleted !=
                              true &&
                            question.questionScored &&
                            question.questionScored > 0
                          "
                          class="font-weight-bold light-green accent-1"
                        >
                          <span style="font-size: 14pt">
                            {{ question.questionScored }}
                          </span>
                        </td>
                        <td
                          v-else-if="
                            cat.groupsEnabled &&
                            categories[catindex].questions[qindex].deleted !=
                              true
                          "
                          class="font-weight-bold red lighten-4"
                        >
                          <span
                            v-if="
                              result.categories[catindex].failedGroups &&
                              result.categories[catindex].failedGroups.find(
                                (failedgroup) =>
                                  failedgroup == question.assignedGroup
                              )
                            "
                            >0</span
                          >
                          <span v-else>
                            {{ getGroupScore(cat, question) }}
                          </span>
                        </td>
                        <td
                          style="display: none"
                          v-else-if="
                            cat.categoryScoreRequired &&
                            categories[catindex].questions[qindex].deleted !=
                              true
                          "
                          class="font-weight-bold red lighten-4"
                        ></td>
                        <td
                          v-else-if="
                            categories[catindex].questions[qindex].deleted !=
                            true
                          "
                          class="font-weight-bold red lighten-4"
                        >
                          <span
                            class="font-weight-bold red--text"
                            style="font-size: 14pt"
                            >0</span
                          >
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-model="closeAsuulgaSlide">
        <v-card>
          <div v-html="_getSlideLink()"></div>
          <div class="d-flex flex-row justify-end">
            <v-btn
              class="bg-gradient-danger white--text mb-3 mr-3"
              @click="closeAsuulgaSlide = !closeAsuulgaSlide"
              >Хаах</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    closeAsuulgaSlide: false,
    asuulgaaa: null,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,
    slide: null,

    result: null,
    categories: null,
    loading: false,
    rules: [],
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },

    showSlide: {
      type: Boolean,
      default: true,
    },
    fromEYESH: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(this.sorilId);
    if (this.fromEYESH) {
      fb.dbeyesh800
        .doc(this.sorilId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.result = doc.data();
            this.result.ref = doc.ref;
            this.result.id = doc.id;
            await fb.dbeyesh800
              .doc(this.result.ref.parent.parent.path)
              .get()
              .then((doc) => {
                let asuulga = doc.data();
                asuulga.ref = doc.ref;
                asuulga.id = doc.id;
                if (asuulga.slideUrl) this.slide = asuulga.slideUrl;
                else if (
                  asuulga.selectedExam &&
                  asuulga.selectedExam.slideUrl
                ) {
                  this.slide = asuulga.selectedExam.slideUrl;
                }

                this.asuulgaaa = asuulga;
                console.log(asuulga);
              });
            //this.result.ref.parent.parent
            console.log(this.result)
            await this._findCategories();
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      fb.db
        .doc(this.sorilId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.result = doc.data();
            this.result.ref = doc.ref;
            this.result.id = doc.id;

            fb.db
              .doc(this.result.ref.parent.parent.path)
              .get()
              .then((doc) => {
                let asuulga = doc.data();
                asuulga.ref = doc.ref;
                asuulga.id = doc.id;
                if (asuulga.slideUrl) this.slide = asuulga.slideUrl;
                else if (
                  asuulga.selectedExam &&
                  asuulga.selectedExam.slideUrl
                ) {
                  this.slide = asuulga.selectedExam.slideUrl;
                }

                this.asuulgaaa = asuulga;
                console.log(asuulga);
                this._findCategories();
              });
            //this.result.ref.parent.parent
            // console.log(this.result)
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  },
  methods: {
    getGroupTotalScore(cat) {
      var totalScore = 0;
      cat.availableGroups.forEach((group) => {
        totalScore = totalScore + group.score;
      });
      return totalScore;
    },
    getGroupScore(cat, question) {
      if (question.answers.find((answer) => answer.answered) == undefined) {
        return 0;
      } else {
        var tmp = cat.availableGroups.find(
          (group) => group.name == question.assignedGroup
        );
        if (tmp) {
          return tmp.score;
        } else {
          return 0;
        }
      }
    },

    // _checkCorrectAnswer(quest) {
    //   if (this.asuulgaaa) {
    //     for (var question of this.categories[0].questions) {
    //       if (question.index == quest.questionIndex) {
    //         for (var answer of question.answers) {
    //           console.log(answer.correct, question.ref.path, answer.name);
    //           if (answer.correct) {
    //             quest.correctAnswer = answer.name;
    //           } else {
    //             quest.correctAnswer = null;
    //           }
    //         }

    //         break;
    //       } //fi
    //     }
    //   }
    // },
    _getSlideLink() {
      var xx = null;
      if (this.slide) {
        if (this.slide.includes("docs.google.com")) {
          xx = this.slide.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            this.slide.replace("/view?usp=sharing", "/preview?embedded=true") +
            "' </iframe>";
        }
      }
      return xx;
    },
    _getSlideLink2(slideUrl) {
      var xx = null;
      if (slideUrl)
        xx = slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;height:300px!important'"
        );
      return xx;
    },
    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },
    _getTotalScore2(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered && answer.correct) {
              question.questionScored =
                question.questionScored + question.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    _answerOpenQuestionInput(question) {
      console.log(question.answeredText);
    },
    _answerDefaultQuestion(question, answer) {
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    async _getQuestionsOfCategory(cat) {
      await this.asuulgaaa.ref
        .collection("categories")
        .doc(cat.id)
        .collection("questions")
        .orderBy("name", "asc")

        .get()
        .then((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;
            question.id = doc.id;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = [];
            //console.log(doc.ref);
            // this._getAnswers(question, doc.ref);

            doc.ref
              .collection("answers")
              .orderBy("name", "asc")
              .get()
              .then((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;
                  answer.answered = false;
                  question.answers.push(answer);
                });
              });
            // this.getGroupScoredScore(cat, question);

            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    getGroupScoredScore() {
      for (var cat of this.result.categories) {
        if (cat.groupsEnabled) {
          if (!cat.failedGroups) cat.failedGroups = [];
          for (var question of cat.questions) {
            for (var answer of question.answers) {
              if (answer.correct == false && answer.answered) {
                if (
                  cat.failedGroups.find(
                    (failedgroup) => failedgroup.name == question.assignedGroup
                  ) == undefined
                )
                  cat.failedGroups.push(question.assignedGroup);
              }
            }
          }
          console.log(cat.failedGroups);
        }
      }
    },
    _findCategories() {
      console.log(this.asuulgaaa);
      this.asuulgaaa.ref
        .collection("categories")
        .orderBy("name", "asc")
        .get()
        .then((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach(async (catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            // cat.questions = [];
            await this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            // if (this.categories.length === 1) {
            //   this.category = this.categories[0];
            // }
          });
          console.log(this.categories);
          this.getGroupScoredScore();
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.correctCell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #6bd94c !important;
  cursor: pointer;
}
.errorCell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #ff9090 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
